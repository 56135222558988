import React, { useState } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import xtraIcon from '../images/xtra-icon.png';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../components/Utils';
import ErrorMessagePopup from '../components/ErrorMessagePopup';
import SafeAreaHeader from '../components/SafeAreaHeader';
import notifySwift from '../constants/notifySwift';
import isCasual from '../constants/isCasual';
import renderToastError from '../renderFunctions/renderToastError';
import Api from '../components/Api';

function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageTitle, setErrorMessageTitle] = useState('');

  const handleError = (title, message) => {
    setIsError(true);
    setErrorMessageTitle(title);
    setErrorMessage(message);
  };

  // Function to close the error popup
  const closeErrorPopup = () => {
    setIsError(false);
    setErrorMessage('');
    setErrorMessageTitle('');
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const getBrowserDeviceToken = () => {
    return navigator.userAgent;
  };

  const casualLogin = async () => {
    try {
      const response = await Api('/customer/login?casuallogin=true', 'POST', {
        email,
        password,
      });

      if (response.status === 200 && response.data.success) {
        const browserDeviceToken = getBrowserDeviceToken();
        localStorage.setItem('customDeviceToken', browserDeviceToken);
        localStorage.setItem('accessToken', response.data.accessToken);
        navigate('/splash');
      } else {
        renderToastError({
          message: 'Incorrect Email or Password Provided',
        });
      }
    } catch (error) {
      renderToastError({
        message: 'Sorry, there was an issue logging in, please try again or contact us for support.',
      });
    }
  };

  const onLoginEvent = () => {
    if (validateEmail(email)) {
      if (password.length > 0) {
        if (isCasual) {
          casualLogin();
        } else {
          notifySwift({
            function: 'login',
            data: {
              email: email,
              password: password,
            },
          });
        }
      } else {
        handleError('Password Invalid', 'Please enter a password.');
      }
    } else {
      handleError('Email Address', 'Invalid email address.');
    }
  };

  return (
    <div
      className="flex flex-col bg-xtraNavy h-screen bg-no-repeat bg-center bg-cover p-10 justify-between"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <div className="flex flex-col">
        <SafeAreaHeader />
        <img className="w-10 h-auto" src={xtraIcon} alt="xtra" />
        <h2 className="mt-5">Welcome</h2>
        <p>Log in to proceed</p>
        <label className="mt-5">Email</label>
        <input name="email" type="email" placeholder="Enter your email address" onChange={onEmailChange}></input>
        <label className="mt-2">Password</label>
        <input name="password" type="password" placeholder="Enter your password" onChange={onPasswordChange}></input>
        <button onClick={onLoginEvent} className="w-full mt-2">
          Log In
        </button>
      </div>
      <div className="flex flex-col">
        <span
          onClick={() => {
            navigate('/reset-password');
          }}
          className="text-white font-bold underline"
        >
          Forgot your password?
        </span>
        <span
          onClick={() => {
            navigate('/login');
          }}
          className="text-white font-bold underline"
        >
          Go Back
        </span>
      </div>
      <ErrorMessagePopup
        message={errorMessage}
        messageTitle={errorMessageTitle}
        isOpen={isError}
        onClose={closeErrorPopup}
      />
    </div>
  );
}

export default Login;
